import React, { useEffect } from "react";
import Layout from "../components/Layout/Layout";
import styles from "./jul.module.scss";

const ChristmasPage = () => {
  useEffect(() => {
    if (typeof window !== undefined) {
      window.location.replace(
        "https://www.youtube.com/playlist?list=PLsDbGEvkaX2IfwxWdKpgkgEWYziFSDc0I&fbclid=IwAR1MZf5K-2I8XyWQjlRsCZ79GSbQe52cZU115W-7fBCwRIiSOAShkOgz184"
      );
    }
  });

  return (
    <Layout>
      <section id="jul" className={styles.section}>
        <div className={styles.container}>
          <div className={styles.julContainer}>
            <h1>God jul</h1>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default ChristmasPage;
